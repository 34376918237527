<template>
  <el-cascader
    ref="siteCascader"
    v-model="value"
    class="w_350"
    :options="siteList"
    size="mini"
    :props="sitePropsData"
    :disabled="disabled"
    @change="changeSite"
  />
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    siteType: {
      type: String,
      default: 'add',
    },
    siteValue: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: [],
      siteList: [],
      sitePropsData: {
        value: 'regionCode',
        label: 'regionName',
        lazy: true,
        lazyLoad: this.lazyLoad,
      },
    };
  },
  computed: {},
  watch: {
    siteValue: {
      async handler(val) {
        console.log(val, '监听siteValue=====');
        if (val) {
          this.siteList = await this.getRegionData(0);
          if (this.siteType !== 'add') {
            this.value = [...val].filter((fil) => !!fil);
            if (val[0]) {
              let cityList = await this.getRegionData(val[0]);
              let index = this.siteList.findIndex((find) => Number(find.regionCode) === Number(val[0]));
              this.$set(this.siteList[index], 'children', cityList);
              // 市code存在，请求县接口；
              if (val[1]) {
                console.log(val[1], 'val[1]=====');
                // 定义this市列表；
                let newCityList = this.siteList[index].children;
                let districtList = await this.getRegionData(val[1]);
                let districtIndex = newCityList.findIndex((find) => Number(find.regionCode) === Number(val[1]));
                this.$set(newCityList[districtIndex], 'children', districtList);
              }
            }
          }
        }
      },
    },
  },
  created() {},
  mounted() {

  },
  methods: {
    changeSite(e) {
      this.$emit('change', e);
      setTimeout(() => {
        this.$emit('input', e, this.$refs.siteCascader.inputValue);
      }, 0);
    },
    async lazyLoad(node, resolve) {
      if (!node.value) return;
      let arr = await this.getRegionData(node.value);
      resolve(arr);
    },
    getRegionData(code) {
      return new Promise((resolve) => {
        let storageArea = sessionStorage.getItem(`selectAreaData${code}`);
        if (storageArea) {
          resolve(JSON.parse(storageArea));
        } else {
          this.$axios.get(this.$api.commonNew.getChildRegion, {
            params: {
              regionCode: code,
            },
          }).then((row) => {
            if (row.code === 0) {
              let siteArr = row.data.map((item) => {
                return {
                  ...item,
                  leaf: item.type >= 2 || item.regionCode === '6600' || item.regionCode === '6700' || item.regionCode === '6800',
                };
              });
              sessionStorage.setItem(`selectAreaData${code}`, JSON.stringify(siteArr));
              resolve(siteArr);
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">

</style>
